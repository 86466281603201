<template>
	<div>
		<div class="flex items-center justify-between mb-4 bg-verdedark">
			<div class="flex w-2/5 items-center justify-between">
				<span class="text-white font-bold ml-2">Editar Cliente</span>
			</div>
			<div class="flex justify-center ">
				<router-link to="/AgregarCliente"
					><button
						class="text-white font-bold py-2 px-4 inline-flex items-center focus:outline-none"
					>
						<svg
							id="Capa_1"
							enable-background="new 0 0 551.13 551.13"
							height="512"
							viewBox="0 0 551.13 551.13"
							width="512"
							class="h-6 w-6 mr-2 fill-current text-white"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="m275.565 0c-151.944 0-275.565 123.621-275.565 275.565s123.621 275.565 275.565 275.565 275.565-123.621 275.565-275.565-123.621-275.565-275.565-275.565zm0 516.685c-132.955 0-241.119-108.164-241.119-241.119s108.164-241.12 241.119-241.12 241.12 108.164 241.12 241.119-108.165 241.12-241.12 241.12z"
							/>
							<path
								d="m292.788 137.783h-34.446v120.56h-120.56v34.446h120.56v120.56h34.446v-120.56h120.56v-34.446h-120.56z"
							/>
						</svg>
						<span class="text-white underline">Añadir Cliente</span>
					</button></router-link
				>
			</div>
		</div>
		<div class="flex justify-center pt-10">
			<table id="Vendedores" class="table-auto mt-4">
				<thead>
					<tr>
						<th class="border-2 px-4 py-2">Cliente</th>
						<th class="border-2 px-4 py-2">C.I.</th>
						<th class="border-2 px-4 py-2">Teléfono</th>
						<th class="border-2 px-4 py-2">Dirección</th>
						<th></th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td class="text-center border-2 px-4 py-4">
							<input type="text" v-model="usuarioID.name" />
						</td>
						<td class="text-center border-2 px-4 py-4">
							<input type="text" v-model="usuarioID.cedula_identidad" />
						</td>
						<td class="text-center border-2 px-4 py-4">
							<input type="text" v-model="usuarioID.numero_telefono_movil" />
						</td>
						<td class="text-center border-2 px-4 py-1">
							<input type="text" v-model="usuarioID.address" />
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="flex w-full content-center items-center justify-center pt-8">
			<Botonp
				class="underline"
				botonpText="Guardar Cambios"
				@click="guardarCliente"
			></Botonp>
		</div>
		<div v-if="showMessage" class="flex justify-center pt-8">
			<p>{{ message }}</p>
		</div>
	</div>
</template>

<script>
import gql from "graphql-tag";
import Botonp from "@/components/botonPrimario";

export default {
	components: {
		Botonp
	},
	data() {
		return {
			usuarioID: [],
			message: "",
			showMessage: false
		};
	},
	mounted() {
		this.cargarCliente();
	},
	methods: {
		cargarCliente: async function() {
			this.$store.state.isLoading = true;
			await this.$apollo
				.query({
					query: gql`
						query usuarioID($id: ID!) {
							usuarioID(id: $id) {
								name
								cedula_identidad
								address
								numero_telefono_movil
							}
						}
					`,
					variables: {
						id: this.$route.params.id
					},
					fetchPolicy: "no-cache"
				})
				.then(data => {
					this.$store.state.isLoading = false;
					this.usuarioID = data.data.usuarioID;
					console.log(this.usuarioID);
				})
				.catch(error => {
					this.$store.state.isLoading = false;
					console.log(error);
					this.showMessage = true;
					this.message = error;
				});
		},
		guardarCliente: async function() {
			this.$store.state.isLoading = true;
			await this.$apollo
				.mutate({
					mutation: gql`
						mutation($id: ID!, $data: UsersUpdate!) {
							guardarCliente(id: $id, input: $data) {
								__typename
							}
						}
					`,
					variables: {
						id: this.$route.params.id,
						data: {
							name: this.usuarioID.name,
							cedula_identidad: this.usuarioID.cedula_identidad,
							address: this.usuarioID.address,
							numero_telefono_movil: this.usuarioID.numero_telefono_movil
						}
					}
				})
				.then(() => {
					this.$store.state.isLoading = false;
					this.message = "Cliente editado con exito.";
					this.showMessage = true;
				})
				.catch(error => {
					this.$store.state.isLoading = false;
					this.message = error;
					this.showMessage = true;
				});
		}
	}
};
</script>

<style></style>
